import {NextSeo} from 'next-seo';
import {Result} from 'antd';
import {Container, Row, Col} from 'reactstrap';
import Image from 'next/image';

const voltar =
  'https://locare.s3.sa-east-1.amazonaws.com/sites/common/remove.png?size=small';

export default function NotFound(): JSX.Element {
  return (
    <>
      <NextSeo title="Página não encontrada" noindex nofollow />
      <div className="page-not-found hero-overly" style={{height: '100vh'}}>
        <div className="d-flex flex-column justify-content-center h-100">
          <Container>
            <Result
              status="404"
              subTitle="Desculpe, Página não encontrada!"
              extra={
                <Row className="my-4 justify-content-md-center no-gutters">
                  <Col xs="12">
                    <a href="/" role="button">
                      <div className="image-buttons-color my-4">
                        <div className="d-flex justify-content-center align-items-center flex-row">
                          <Image src={voltar} height={32} width={32} />
                          <span className="image-buttons-label-color">
                            Voltar para início
                          </span>
                        </div>
                      </div>
                    </a>
                  </Col>
                </Row>
              }
            />
          </Container>
        </div>
      </div>
    </>
  );
}
